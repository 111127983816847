<template>
    <div v-if="isDataSourceCorrect" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel
            :document-info="this.dataSource.Data.Object.Resolution"
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="item.FormId"
            >
                <template v-if="item.FormId == order.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <!--#region Реквизиты документа-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <v-card-panel
                                    v-if="!order.is_new_record"
                                    title="Сведения_о_регистрации"
                                >
                                    <v-field label="Номер_регистрации">
                                        <template v-slot:view>
                                            {{ order.Number }}
                                        </template>
                                    </v-field>                               
                                </v-card-panel>

                                <v-card-panel title="Реквизиты_поручения">
                                    <v-field label="Текст_поручения">
                                        <template v-if="isViewMode" v-slot:view>{{ task.ResolutionText }}</template>
                                        <template v-else v-slot:edit>
                                            <div class="icon-click-row-group">
                                                <div @click="onSelectResolutionText">
                                                    <v-icon small>
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <v-textarea
                                                    :value="task.ResolutionText"
                                                    @input="update({ property: 'Data.Object.Resolution.Tasks[0].ResolutionText', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </div>

                                        </template>
                                    </v-field>

                                    <v-field label="Срок">
                                        <template v-if="isViewMode" v-slot:view>{{ (task.is_new_record ? formatDate(task.MaxControlDate) : formatDate(task.InnerLimit)) }}</template>
                                        <template v-else v-slot:edit>
                                            <v-menu 
                                                v-model="controleDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="innerLimit | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="innerLimit"
                                                    @input="controleDateMenu = false"
                                                    color="teal"
                                                    :max="controlDateRange.Max"
                                                    :min="controlDateRange.Min"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>

                                        </template>
                                    </v-field>

                                    <v-field label="Сроки_продлений">
                                        <template v-slot:view>
                                            {{ (!task.ProlongationDateList ? $t("Не_указано") : task.ProlongationDateList.replaceAll(" 0:00:00", "")) }}
                                        </template>
                                    </v-field>

                                    <v-field label="Приоритет">
                                        <template v-if="isViewMode" v-slot:view>{{ task.ControlPriority ? task.ControlPriority.Value : $t("Не_указано") }}</template>
                                        <template v-else v-slot:edit>
                                                <v-select
                                                    :value="task.ControlPriority"
                                                    @input="update({ property: 'Data.Object.Resolution.Tasks[0].ControlPriority', value: $event })"
                                                    :items="GetReference(1008)" 
                                                    :item-text="item => $refLocale(item, $i18n.locale)" 
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                </v-select>
                                        </template>
                                    </v-field>

                                    <v-field label="Контролирующий">
                                        <template v-if="isViewMode" v-slot:view>
                                            <div class="onlyReadData">
                                                <v-workplace-chip
                                                    v-if="task.ControlerWorkPlaceId != guidEmpty"
                                                    :id="task.ControlerWorkPlaceId"
                                                    :name="task.ControlerName">
                                                </v-workplace-chip>
                                                <template
                                                    v-else
                                                >
                                                    {{ $t("Не_указано") }}
                                                </template>
                                            </div>
                                        </template>
                                        <template v-else v-slot:edit>
                                            <div class="icon-click-row-group">

                                                <div @click="onSelectControler">
                                                    <v-icon small>
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>

                                                <div class="onlyReadData">
                                                    <v-workplace-chip
                                                        v-if="task.ControlerWorkPlaceId != guidEmpty"
                                                        :id="task.ControlerWorkPlaceId"
                                                        :name="task.ControlerName">
                                                    </v-workplace-chip>
                                                    <template
                                                        v-else
                                                    >
                                                        {{ $t("Не_указано") }}
                                                    </template>
                                                </div>

                                            </div>
                                        </template>
                                    </v-field>

                                    <v-field label="Статус">
                                        <template v-slot:view>
                                            <StatusChip 
                                                :status="availableStatuses[$helpers.getTaskStatus(task.WorkStatus)]"
                                            />                                        
                                        </template>
                                    </v-field>

                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->

                            <!--#region Кем создано-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card-panel title="Кем_создано" v-if="isViewMode">
                                    <v-field label="Автор">
                                        <template v-slot:view>
                                            <v-employee-chip
                                                :id="dataSource.Data.Object.Resolution.SignerID"
                                                :name="dataSource.Data.Object.Resolution.SignerName"
                                            >
                                            </v-employee-chip>
                                        </template>
                                    </v-field>
                                    <v-field label="Дата_создания">
                                        <template v-slot:view>
                                            {{ dataSource.Data.Object.Resolution.ResolutionDate ? formatDate(dataSource.Data.Object.Resolution.ResolutionDate) : $t('Не_указано') }}
                                        </template>
                                    </v-field>                                    
                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->

                            <!--#region Исполнители-->
                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <v-card-panel title="Исполнители">
                                    
                                    <template v-slot:titleappend>
                                        <span
                                            class="fbt-after-edit"
                                            v-if="!isViewMode"
                                            @click="onExecuterSelect"
                                        >
                                            <v-icon 
                                                small 
                                                v-tooltip.left-center="selectExecuterTooltip"
                                            >
                                                fas fa-edit
                                            </v-icon>
                                        </span>
                                    </template>

                                    <template v-if="isViewMode">

                                        <v-field
                                            v-if="executers.length > 0"
                                            label="Исполнители"
                                            :wide="true"
                                            :multi="true"
                                        >

                                            <template v-slot:view>
                                                <template v-for="executer in executers">
                                                    <v-enterprise-chip
                                                        v-if="executer.ExecuterID == guidEmpty"
                                                        :key="executer.id"
                                                        :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.id"
                                                        :id="executer.ExecuterID"
                                                        :name="executer.ExecuterName"
                                                    />
                                                </template>
                                            </template>

                                        </v-field>

                                    </template>
                                    <template v-else>

                                        <v-field
                                            label="Исполнители"
                                            :wide="true"
                                        >
                                            <template v-slot:edit>
                                                <draggable
                                                    v-model="executers"
                                                    v-bind="draggableOptions"
                                                    :disabled="true"
                                                    :class="['more-per-lab-wrap', 'onlyReadData']"
                                                >
                                                    <div class="chipAreaNoData" v-if="executers.length == 0"></div>
                                                    <template v-else v-for="executer in executers">
                                                        <v-enterprise-chip
                                                            v-if="executer.ExecuterID == guidEmpty"
                                                            :key="executer.id"
                                                            :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :key="executer.id"
                                                            :id="executer.ExecuterID"
                                                            :name="executer.ExecuterName"
                                                        />
                                                    </template>

                                                </draggable>                                            
                                            </template>
                                        </v-field>
                                    </template>

                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->
                            
                            <!--#region Ход исполнения-->
                            <v-col v-if="isViewMode" cols="12" sm="12" md="12" class="section-wrapper">
                                <v-card-panel title="Ход_исполнения">

                                    <template v-slot:titleactions>              
                                        <div class="fbt-slider">
                                            <div class="prev-title">{{$t('Показать_все_исполнения')}}</div>
                                            <v-switch 
                                                v-model="allExecutions" 
                                                class="cust-switch" 
                                                inset 
                                                hide-details                                               
                                            >
                                            </v-switch>
                                            <div
                                                class="arrow-dropdown"
                                                @click="toggleExpanded"
                                            >
                                                <v-icon 
                                                role="button" 
                                                small 
                                                left>
                                                    {{ expanded ? "fas fa-chevron-up" : "fas fa-chevron-down" }}
                                                </v-icon>
                                            </div>
                                        </div>
                                    </template>
                    
                                    <v-execution-tree
                                        :task-click="taskClickHandler"
                                        :task-dbl-click="taskDblClickHandler"
                                        :allExecutions="allExecutions"
                                        :expanded.sync="expanded"
                                        :is-order-tree="true"
                                    >
                                    </v-execution-tree>
                                </v-card-panel>
                            </v-col>
                            <!--#endregion-->
                            
                        </v-row>
                    </v-form>
                </template>
                
                <template v-else>
                    <component                         
                        :is="$mapComponent(item.FormId)"
                        :ref="item.FormId"
                        :id="order.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

        <SelectResolutionTextDlg v-if="!isViewMode" ref="SelectResolutionTextDlgRef" />

    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import SelectResolutionTextDlg from '@/components/dialogs/SelectResolutionTextDlg';
import { mapActions, mapGetters } from 'vuex';
import StatusChip from '@/components/StatusChip';
import sys from '@/services/system';
import draggable from 'vuedraggable';
import _ from 'lodash';
import i18n from '@/i18n';

export default {
    name: "Order",
    components: {
        Toolbar,
        DocumentPanel,
        SelectResolutionTextDlg,
        StatusChip,
        draggable
    },     
    data() {
        return {
            tab: null,
            openAll: true,
            allExecutions: false,
            expanded: false,
            controleDateMenu: false,

            requiredRules: [
                v => !!v || v === 0 || 'Поле_обязательно',
            ],
            draggableOptions: {
                animation: 300,
                group: "group",
                disabled: false,
            },
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('references', ['GetReference']),
        ...mapGetters('actionsource', { dataSource: 'getDataSource'}),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule'
        }),
        isDataSourceCorrect() {                
            return this.dataSource?.Data?.Object?.Resolution?.__type === "Order:#Avrora.Objects.Modules.Docflow.DocflowObjects";
        },
        controlDateRange() {
            let max = this.$moment(this.task.MaxControlDate)
            let min = this.$moment(this.dataSource?.Data?.Object?.Resolution?.ResolutionDate);

            let Max = "";
            let Min = "";

            if (max && max.isValid()) {
                Max = max.format('YYYY-MM-DD');

                if (min && min.isValid() && min.isBefore(max))                           
                    Min = min.format('YYYY-MM-DD');       
            }
            else if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }

            return { Max, Min };
        },
        innerLimit: {
            get: function() {
                if (this.task.InnerLimit)
                    return this.$moment(this.task.InnerLimit).format('YYYY-MM-DD');
                
                return this.task.InnerLimit;
            },
            set: function(v) {
                this.updateSource({ property: `Data.Object.Resolution.Tasks[0].InnerLimit`, value: `/Date(${Date.parse(v)})/` });
            }
        },
        guidEmpty() {
            return '00000000-0000-0000-0000-000000000000';
        },
        availableStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        isViewMode() {
            return this.dataSource?.Data?.FormId == "0401021";
        },
        task() {
            return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0]
        },
        order() {
            return this.dataSource?.Data?.Object?.Resolution;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.order?.id, Name: this.$t("Карточка_поручения") }
            ];

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

            return pages;
        },
        executers: {
            get: function () { 
                return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers;
            },
            set: async function (value) {
                if (value.length > 0 && value.length == this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers.length)
                    this.updateSource({ property: `Data.Object.Resolution.Tasks[0].Executers`, value });

                return;
            }
        },
        selectExecuterTooltip() {
            return this.$t("Выбрать_исполнителей");
        },
    },
    methods: {
        ...mapActions('references', ['getReference']),
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),   
        toggleExpanded() {
            this.expanded = !this.expanded;                          
        },
        onTabClicked (formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => { return i.split('/')[1] })
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if(validate_result && this.executers.length > 0){
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Action ${button.Action} not emplemented`);
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        taskClickHandler() {

        },
        taskDblClickHandler(item) {
            if (item.text === '2')
                this.$store.dispatch(`actionsource/ExecuterShowExecution`, { executionData : { resolutionId: item.viewInfo.resolutionId, executionId: item.id, common: false } });
        },
        async onSelectResolutionText() {
            try
            {
                let resolutionText = await this.$refs.SelectResolutionTextDlgRef.open({ title: 'Справочник_текста_поручений' });
                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].ResolutionText', value: resolutionText });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async onExecuterSelect() {
            try
            {
                let selectMemberParams =  {
                    title: this.$t("Выбор_исполнителей"),
                    includeInner: true,
                    includeExecutersGroups: true,
                    multiple: true,
                    selected: Array.from(this.task.Executers)
                        .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1)
                        .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID),
                    implicitExclude: [ this.dataSource.Data.Object.Resolution.SignerWorkPlaceId ]
                }

                let result = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);

                let executersDataList = result.map(x =>
                    [
                        x.employeeId ? x.workplaceId : x.enterprise,
                        x.name,
                        x.employeeId ? "сотрудники" : "организации",
                        x.workplaceId
                    ]);

                let resolutionTaskExecuters = [];

                result.forEach( (selected, index) => {
                    let resolutionTaskExecuter = sys.prepareResolutionTaskExecuter(this.task.id, index, selected);
                    resolutionTaskExecuters.push(resolutionTaskExecuter);
                });
                        
                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value: resolutionTaskExecuters });
                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].WebExecuterDataList.Data', value: executersDataList });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async onSelectControler() {
            let selectMemberParams =  {
                title: "Выбор_контролирующего",
                includeInner: true,
                multiple: false,
                selected: [ this.task.ControlerWorkPlaceId ]
            };

            let result = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);

            this.updateSource({ property: `Data.Object.Resolution.Tasks[0].ControlerWorkPlaceId`, value: result.workplaceId });
            this.updateSource({ property: `Data.Object.Resolution.Tasks[0].ControlerEmployeeId`, value: result.employeeId });
            this.updateSource({ property: `Data.Object.Resolution.Tasks[0].ControlerName`, value: result.name });
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    }
}
</script>